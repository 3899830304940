import { createApp } from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import axios from 'axios';

// axios.defaults.baseURL = 'https://hx.thego.cn:8001';
axios.defaults.baseURL = 'http://human.zhuoying-tech.com/web/';

// axios.defaults.baseURL = 'http://127.0.0.1:8080/web/';

const app = createApp(App);
app.use(ElementPlus);
app.config.globalProperties.$axios=axios;  //配置axios的全局引用

app.mount('#app');





