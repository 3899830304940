<template>
    <div id="content" style="margin-top: 50px;">
        <!-- <div class="textarea-box">
            <el-input type="textarea" name="" autosize placeholder="" v-model="stock_list"></el-input>
            <el-button type="primary" v-bind:disabled="disabled" plain @click="update_stock_list">提交股票池</el-button>
        </div> -->
        <div class="textarea-box">
            <el-input type="textarea" autosize placeholder="请输入股票代码" v-model="code">
            </el-input>
            <el-button type="success" v-bind:disabled="disabled" plain @click="search">查询今日因子</el-button>
        </div>
        <div class="textarea-box" style="padding-bottom: 30px;">
            <el-col :span="24" style="border: none !important;">
                <el-row>
                     <el-input style="width: 100% !important" type="textarea" rows=8 placeholder="" v-model="answerdata_factor"></el-input>
                    <!-- <p :style="{ 'font-size': '12px', 'line-height': '1','text-align': 'left'}"> {{ answerdata_factor}}</p> -->
                </el-row>
                <!-- <el-input type="textarea" rows=13 placeholder="" v-model="answerdata2"></el-input> -->
            </el-col>
           
        </div>
        <div class="textarea-box">
            <el-button type="warning" v-bind:disabled="disabled" plain @click="search2">查询成功率</el-button>
        </div>
        <div>
            <div class="his_label">
                <div class="label">历史成功率</div>
                <div class="label">全市场成功率</div>
            </div>
        </div>
       

        <div class="his_result">
            <!-- <el-row> -->
                <el-col :span="11">
                    <el-row>
                        <p :style="{ 'font-size': '12px', 'line-height': '1','text-align': 'left'}"> 出现次数: {{ answerdata2.total  || 'N/A' }}</p>
                       
                    </el-row>
                    <el-row>

                        <p :style="{ 'font-size': '12px', 'line-height': '1','text-align': 'left'}"> 1日上涨概率: {{ answerdata2['day_1_up_ratio'] || 'N/A' }}</p>
                        
                    </el-row>
                    <el-row>
                        <p :style="{ 'font-size': '12px', 'line-height': '1','text-align': 'left'}"> 3日上涨概率: {{ answerdata2['day_3_up_ratio'] || 'N/A' }}</p>
                    </el-row>
                    <el-row>
                         <p :style="{ 'font-size': '12px', 'line-height': '1','text-align': 'left'}"> 5日上涨概率: {{ answerdata2['day_5_up_ratio'] || 'N/A' }}</p>
                       
                    </el-row>
                    <el-row>
                        <p :style="{ 'font-size': '12px', 'line-height': '1','text-align': 'left'}">{{answerdata2['his_data']}}</p>
                    </el-row>
                    <!-- <el-input type="textarea" rows=13 placeholder="" v-model="answerdata2"></el-input> -->
                </el-col>
                <el-col :span="2" style="border: none !important;"></el-col>
                <el-col :span="11">
                    <el-row>
                        <p :style="{ 'font-size': '12px', 'line-height': '1','text-align': 'left'}"> 选中股票数（随机选取100支）: {{ answerdata3.total || 'N/A'  }}</p>
                       
                    </el-row>
                    <el-row>

                        <p :style="{ 'font-size': '12px', 'line-height': '1','text-align': 'left'}"> 1日上涨概率: {{ answerdata3['day_1_up_ratio'] || 'N/A' }}</p>
                        
                    </el-row>
                    <el-row>
                        <p :style="{ 'font-size': '12px', 'line-height': '1','text-align': 'left'}"> 3日上涨概率: {{ answerdata3['day_3_up_ratio'] || 'N/A' }}</p>
                    </el-row>
                    <el-row>
                         <p :style="{ 'font-size': '12px', 'line-height': '1','text-align': 'left'}"> 5日上涨概率: {{ answerdata3['day_5_up_ratio'] || 'N/A' }}</p>
                       
                    </el-row>
                    <el-row v-for="(item, key) in answerdata3.his_data" :key="key">
                        <p :style="{ 'font-size': '12px', 'line-height': '1','text-align': 'left'}">{{ key }}: {{ item }}</p>
                    </el-row>
                    <!-- <el-input type="textarea" rows=10 placeholder="" v-model="answerdata3.his_data"> </el-input> -->
                </el-col>
            <!-- </el-row> -->
            
        </div>
        <!--  <div class="textarea-box">
            <el-input type="textarea" autosize="{ minRows: 3, maxRows: 10}" placeholder="" v-model="answerdata2">
            </el-input>
            <el-button type="warning" v-bind:disabled="disabled" plain @click="search3">全市场成功率</el-button>
        </div>
        <div class="info-box clear">
            <el-row>
                选中股票数（随机100只）: {{ answerdata3.total }}
            </el-row>
            <el-row>
                1日上涨概率: {{ answerdata3['day_1_up_ratio'] || 'N/A' }}
            </el-row>
            <el-row>
                3日上涨概率: {{ answerdata3['day_3_up_ratio'] || 'N/A' }}
            </el-row>
            <el-row>
                5日上涨概率: {{ answerdata3['day_5_up_ratio'] || 'N/A' }}
            </el-row>
        </div>
        <div class="info-box">
            <el-row v-for="(item, key) in answerdata3.his_data" :key="key">
                <p :style="{ 'font-size': '12px', 'line-height': '1','text-align': 'left'}">{{ key }}: {{ item }}</p>
            </el-row>
        </div> -->
        <div class="factor-box">
            <div class="clear">
                <el-row>
                    <p>因子列表</p>
                </el-row>
                <el-upload ref="upload" drag accept=".csv" :multiple="false" :limit="1" :on-remove="remove" :on-change="handleChange" :file-list="fileList" :on-exceed="exceed" :auto-upload="true" :on-progress="uploading" :http-request="handleFileUpload">
                    <p class="text1">拖拽文件至此区域</p>
                    <p class="text2" id="text2">或</p>
                    <p class="text3" id="text3">浏览本地文件</p>
                </el-upload>
                <el-row>
                    <!-- <el-button type="success" @click.prevent="uploadFile">上传</el-button> -->
                    <!-- <el-button type="success" @click.prevent="downloadFile">下载</el-button> -->
                </el-row>
                <!--  <el-row>
                    <form enctype="multipart/form-data" style="margin-top: 20px">
                        <div>
                            <input type="file" name="file" ref="file" />
                        </div>
                        <div>
                            <el-button type="success" @click.prevent="uploadFile">上传</el-button>
                            <el-button type="warning" @click.prevent="downloadFile">下载</el-button>
                        </div>
                    </form>
                </el-row> -->
                <div class="clear">
                    <el-row class="factor-items" v-for="item in items" :key="item.id">
                        <el-input style="width: 45%;" v-model="item.name" />
                        <el-input style="width: 15%;" v-model="item.description" />
                        <el-button type="primary" plain v-bind:disabled="disabled" @click="editItem(item)">编辑</el-button>
                        <el-button type="warning" plain v-bind:disabled="disabled" @click="deleteItem(item.id)">删除</el-button>
                    </el-row>
                </div>
                <div class="clear">
                    <el-row class="factor-items">
                        <el-input style="width: 60%;" v-model='addData.name' type="text" placeholder="名称"></el-input>
                        <el-input style="width: 20%;" v-model='addData.description' type="text" placeholder="描述"></el-input>
                        <el-button type="primary" v-bind:disabled="disabled" plain @click="addItem()">添加</el-button>
                    </el-row>
                </div>
                <!--<h3>添加因子</h3>-->
            </div>
            <div class="clear">
                <!-- <el-button type='primary' v-bind:disabled="disabled" @click="update_minutes">15分钟线更新</el-button> -->
                <!-- <el-button type='primary' v-bind:disabled="disabled" @click="update">日线更新</el-button> -->
                <!-- <el-button type='success' v-bind:disabled="disabled" @click="init">初始化因子</el-button> -->
                <!-- <el-button type='success' v-bind:disabled="disabled" @click="cal_rencently">实时计算因子</el-button> -->
                <p>日线最新时间: {{ updates['daily_refresh'] || 'N/A' }}</p>
                <!-- <p>605111: {{ updates['605111_daily'] || 'N/A' }}</p> -->
                <!-- <p>股票池最新时间: {{ updates['minute_refresh'] || 'N/A' }}</p> -->
                <p>因子计算最新时间: {{ updates['calc_refresh'] || 'N/A' }}</p>
                <!-- <p>605111: {{ updates['605111_calc'] || 'N/A' }}</p> -->
            </div>
        </div>
    </div>
</template>
<script>
import qs from 'qs'
export default {
    name: 'MyComponent',
    data() {
        return {
            source: null,
            code: '605111',
            question2: '',
            answerdata: '',
            answerdata_org: '',
            answerdata2: '',
            answerdata3: '',
            answerdata_factor: '',
            items: '',
            updates: '',
            stock_list: '',
            outVisible: true,
            innerVisible: false,
            formData: { date: '', name: '', address: '' },
            addData: { name: '', description: '' },
            disabled: false
        };
    },
    props: {},
    mounted() {
        this.getItems();
        this.getStocklist();
        this.getUpdate();
        this.outVisible = true
        // setInterval(() => { 
        //    this.getUpdate()
        // },30000)
        // this.handleOpenDialog();
    },
    computed: {

        cal_code: function() {
            // 计算属性：反转消息字符串
            if (this.code && this.code.length > 0) {
                // 获取code字符串的第一个字符
                // const firstChar = this.code.charAt(0);

                // 根据第一个字符添加不同的后缀
                // if (firstChar === '6') {
                //     return this.code + '_XSHG';
                // } else {
                //     return this.code + '_XSHE';
                // }
                return this.code
            } else {
                return this.code; // 如果code为空或无效，返回原始code
            }
        },
    },
    methods: {
        handleOpenDialog() {
            this.outVisible = true
        },
        outClose(done) {
            this.$confirm('确认关闭', '提示框').then(() => {
                done()
            }).catch(() => {

            })
        },
        handleEdit(row) {
            this.formData = row
            this.innerVisible = true
        },
        uploading() {
            //上传中
            console.log("uploading")
            if (this.uploadProgress == 100) {
                //上传完成
                this.uploadStatus = true
            }
        },
        handleChange() {
            //添加文件（样式变化）
            var el = document.getElementById("text2")
            var ele = document.getElementById("text3")
            if (this.fileList != null) {
                el.style.display = "none"
                ele.style.display = "none"
            }
        },
        remove() {
            //删除视频的钩子
            var el = document.getElementById("text2")
            var ele = document.getElementById("text3")
            el.style.display = "inline-block"
            ele.style.display = "inline-block"
        },
        exceed() {
            //重复选择文件,弹出错误弹窗
            this.$alert("最多仅支持上传一个文件")
        },
        handleFileUpload(file) {
            //上传文件
            // let element = this.$refs.upload
            // element.submit();

            var formData = new FormData();
            this.disabled = true

            console.log(this.disabled)
            // formData.append('file', this.$refs.file.files[0]);
            formData.append('file', file.file);

            this.$axios({
                url: 'upload_file',
                data: formData,
                headers: { 'Content-type': 'multipart/form-data' },
                method: 'post',
            }).then((response) => {
                console.log(response)
                if (response.data.status) {
                    this.$confirm(response.data.msg, '提示框', {
                        iconClass: "el-icon-question", //自定义图标样式
                        confirmButtonText: "确认", //确认按钮文字更换
                        cancelButtonText: "取消", //取消按钮文字更换
                        showClose: true, //是否显示右上角关闭按钮
                        type: "info", //提示类型  success/info/warning/error
                    })
                    // this.$.success('上传成功!')
                } else {
                    this.$confirm(response.data.msg, '提示框', {
                        iconClass: "el-icon-question", //自定义图标样式
                        confirmButtonText: "确认", //确认按钮文字更换
                        cancelButtonText: "取消", //取消按钮文字更换
                        showClose: true, //是否显示右上角关闭按钮
                        type: "info", //提示类型  success/info/warning/error
                    })
                }

            }).catch((error) => {
                console.log(error, 'error')
                this.$refs.upload.clearFiles()
            })
            this.disabled = false

        },

        getStocklist: function() {

            this.$axios.get('get_stock_list')
                .then((response) => {
                    this.stock_list = response.data.codes_list;
                })
                .catch(error => {
                    console.error(error);
                });

        },


        update_stock_list: function() {
            this.disabled = true
            console.log(this.disabled)
            this.$axios.post('update_stock_list', qs.stringify({ stock_list: this.stock_list }))
                .then((response) => {
                    this.$confirm(response.data.msg, '提示框', {
                        iconClass: "el-icon-question", //自定义图标样式
                        confirmButtonText: "确认", //确认按钮文字更换
                        cancelButtonText: "取消", //取消按钮文字更换
                        showClose: true, //是否显示右上角关闭按钮
                        type: "info", //提示类型  success/info/warning/error
                    }).then(() => {
                        let timer = setInterval(() => {
                            this.second--; // 每隔1s秒减1
                            if (this.second == 0) {
                                clearInterval(timer);
                                this.$confirm.close()
                            }
                        }, 1000);

                    }).catch(() => {

                    })
                })
                .catch(error => {
                    console.error(error);
                });
            this.disabled = false

        },
        getUpdate: function() {

            this.$axios.get('get_updates')
                .then((response) => {
                    this.updates = response.data;
                })
                .catch(error => {
                    console.error(error);
                });

        },

        downloadFile: function() {
            console.log('downloadFile')
            // window.location.href = `file/factors.csv`;
        },
        init: function() {
            this.disabled = true
            this.answerdata = '请求中，请耐心等待...';
            this.$axios.get('init?flag=1')
                .then((response) => { // use arrow function to preserve Vue instance's "this"
                    this.$confirm(response.data.msg, '提示框', {
                        iconClass: "el-icon-question", //自定义图标样式
                        confirmButtonText: "确认", //确认按钮文字更换
                        cancelButtonText: "取消", //取消按钮文字更换
                        showClose: true, //是否显示右上角关闭按钮
                        type: "info", //提示类型  success/info/warning/error
                    }).then(() => {
                        let timer = setInterval(() => {
                            this.second--; // 每隔1s秒减1
                            if (this.second == 0) {
                                clearInterval(timer);
                                this.$confirm.close()
                            }
                        }, 1000);

                    }).catch(() => {

                    })
                    this.getUpdate();
                    this.disabled = false
                });
        },
        cal_rencently: function() {
            this.disabled = true
            this.answerdata = '请求中，请耐心等待...';
            this.$axios.get('cal_rencently?flag=2')
                .then(() => { // use arrow function to preserve Vue instance's "this"
                    // 更新时间
                    this.$confirm('正在计算，请稍后', '提示框', {
                        iconClass: "el-icon-question", //自定义图标样式
                        confirmButtonText: "确认", //确认按钮文字更换
                        cancelButtonText: "取消", //取消按钮文字更换
                        showClose: true, //是否显示右上角关闭按钮
                        type: "info", //提示类型  success/info/warning/error
                    }).then(() => {
                        let timer = setInterval(() => {
                            this.second--; // 每隔1s秒减1
                            if (this.second == 0) {
                                clearInterval(timer);
                                this.$confirm.close()
                            }
                        }, 1000);

                    }).catch(() => {

                    })
                    this.getUpdate();
                    this.disabled = false
                });
        },
        update: function() {
            this.disabled = true
            this.answerdata = '请求中，请耐心等待...';
            this.$axios.get('update?flag=1')
                .then((response) => { 

                    this.$confirm(response.data.msg, '提示框', {
                        iconClass: "el-icon-question", //自定义图标样式
                        confirmButtonText: "确认", //确认按钮文字更换
                        cancelButtonText: "取消", //取消按钮文字更换
                        showClose: true, //是否显示右上角关闭按钮
                        type: "info", //提示类型  success/info/warning/error
                    }).then(() => {
                        let timer = setInterval(() => {
                            this.second--; // 每隔1s秒减1
                            if (this.second == 0) {
                                clearInterval(timer);
                                this.$confirm.close()
                            }
                        }, 1000);

                    }).catch(() => {

                    })
                    this.getUpdate();
                    this.disabled = false
                });
        },
        update_minutes: function() {
            this.disabled = true
            this.answerdata = '请求中，请耐心等待...';
            this.$axios.get('update_minutes?flag=2')
                .then((response) => { // use arrow function to preserve Vue instance's "this"
                    // 更新时间
                    this.$confirm(response.data.msg, '提示框', {
                        iconClass: "el-icon-question", //自定义图标样式
                        confirmButtonText: "确认", //确认按钮文字更换
                        cancelButtonText: "取消", //取消按钮文字更换
                        showClose: true, //是否显示右上角关闭按钮
                        type: "info", //提示类型  success/info/warning/error
                    }).then(() => {
                        let timer = setInterval(() => {
                            this.second--; // 每隔1s秒减1
                            if (this.second == 0) {
                                clearInterval(timer);
                                this.$confirm.close()
                            }
                        }, 1000);

                    }).catch(() => {

                    })
                    this.getUpdate();
                    this.disabled = false;
                });
        },
        search: function() {
            this.disabled = true
            console.log(this.disabled)
            this.answerdata = '请求中，请耐心等待...';
            this.$axios.get('req_factor?code=' + this.cal_code)
                .then((response) => {
                    console.log(response)
                    this.answerdata = JSON.stringify(response.data);
                    this.answerdata_factor = JSON.stringify(response.data.factors, null, 2);
                    this.disabled = false
                });
            // this.disabled=false
        },

        search2: function() {
            // 将字符串解析为对象
            this.disabled = true
            const paramsObject = JSON.parse(this.answerdata_factor);
            // 创建URLSearchParams对象
            const urlParams = new URLSearchParams();
            // 遍历对象的属性，并将其添加到URLSearchParams对象中
            for (const key in paramsObject) {
                urlParams.append(key, paramsObject[key]);
            }
            this.$axios.get('req_his_win?code=' + this.cal_code + '&' + urlParams.toString())
                .then((response) => { // use arrow function to preserve Vue instance's "this"
                    console.log(response.data)
                    // this.answerdata2 = JSON.stringify(response.data, null, 2);
                    this.answerdata2 = response.data;

                });

            this.$axios.get('req_all_his_win?' + urlParams.toString())
                .then((response) => { // use arrow function to preserve Vue instance's "this"
                    this.answerdata3 = response.data;
                    this.disabled = false
                });


        },
        search3: function() {
            // 将字符串解析为对象
            this.disabled = true
            console.log(this.answerdata_factor)
            const paramsObject = JSON.parse(this.answerdata_factor);
            // 创建URLSearchParams对象
            const urlParams = new URLSearchParams();
            // 遍历对象的属性，并将其添加到URLSearchParams对象中
            for (const key in paramsObject) {
                urlParams.append(key, paramsObject[key]);
            }
            this.$axios.get('req_all_his_win?' + urlParams.toString())
                .then((response) => { // use arrow function to preserve Vue instance's "this"
                    this.answerdata3 = response.data;
                    this.disabled = false
                });
        },
        editItem(item) {
            this.disabled = true
            var data = {
                "id": item.id,
                "name": item.name,
                "description": item.description
            }
            this.$axios({
                url: 'update_item',
                data: data,
                headers: { 'Content-type': 'multipart/form-data' },
                method: 'post',
            }).then((response) => {
                if (response.status) {
                    this.getItems()
                    this.$.success(response.msg)
                } else {
                    this.$.success(response.msg)
                }

            }).catch((error) => {
                console.log(error, 'error')
            })
            this.disabled = false
        },
        saveItem() {
            this.disabled = true
            this.$axios.put(`items/${this.editingItem.id}`, {
                    name: this.editingItem.name,
                    description: this.editingItem.description
                })
                .then(() => {
                    this.getItems();

                })
                .catch(error => {
                    console.error(error);
                });
            this.disabled = false
        },

        getItems() {
            this.$axios.get('items')
                .then((response) => {
                    this.items = response.data.factors;
                    this.items.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    });
                })
                .catch(error => {
                    console.error(error);
                });
        },
        addItem() {
            const newItem = {
                id: 0,
                name: this.addData.name,
                description: this.addData.description,
            };
            console.log(newItem)

            this.$axios({
                url: 'update_item',
                data: newItem,
                headers: { 'Content-type': 'multipart/form-data' },
                method: 'post',
            }).then((response) => {
                if (response.status) {
                    this.getItems()
                    this.$.success(response.msg)
                } else {
                    this.$.success(response.msg)
                }

            }).catch((error) => {
                console.log(error, 'error')
            })
            // this.$axios.post('items/', newItem)
            //     .then(() => {
            //         this.getItems();
            //         this.id = '';
            //         this.name = '';
            //         this.description = '';
            //     })
            //     .catch(error => {
            //         console.error(error);
            //     });
        },
        deleteItem(item_id) {

            this.$axios({
                url: 'delete_item',
                data: { id: item_id },
                headers: { 'Content-type': 'multipart/form-data' },
                method: 'post',
            }).then((response) => {
                if (response.status) {
                    this.getItems()
                    this.$.success(response.msg)
                } else {
                    this.$.success(response.msg)
                }

            }).catch((error) => {
                console.log(error, 'error')
            })

        },
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>