<template>
    <header class="VPNav nav-bar stick">
    <div class='VPNavBar'>
      <div class='web-title'>
        <div class="VPNavBarTitle">
          <img class='logo' alt="Vue logo" src="./assets/logo.png">
        </div>
        <div class="content">股票分析系统</div>
      </div>
    </div>
  </header>
    <HelloWorld msg="Welcome to Your Vue.js App" />
</template>
<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
    name: 'App',
    components: {
        HelloWorld
    }
}
</script>
<style>
body{
    width: 100%;
    margin: 0;
}
.VPNav {
    position: fixed;
    top: var(--vt-banner-height, 0px);
    width: 100%;
    height: 55px;
    border-bottom: #ececec solid 1px;
    z-index: 1000;
    background: #ffffff;
}
.VPNavBar{
    height: 50px;
}
.web-title{
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 98%;
    height: 50px !important;
    display: inline-flex;
    /*background-color: #333333;*/
}
.VPNavBarTitle {
    display: flex;
    align-items: center;
    width: 5%;
    padding-top: 1px;
    height: 50px
}
.VPNavBarTitle .logo{
    margin: 5px;
    width: 40px;
    height: 40px;
    display: block;
    clear: both;
}
.content{
    display: flex;
    justify-content: left;
    align-items: center;
    flex-grow: 1;
}


#content {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 90%;
    margin:100px auto;
    padding-top: 50px;
    max-height: 200px;
}
#content .textarea-box{
    width: 50%;
    clear: both;

    /*display: inline-flex;*/
    margin: 10px auto;
}
.textarea-box .el-col{
    border: #cdcdcd solid 1px;
    border-radius: 5px;
    height: 200px;
    overflow: scroll;
    padding: 1px 5px;
}
.his_label{
    width: 50%;
    display: inline-flex;
}
.his_result{
    width: 50%;
    display: inline-flex;
    clear: both;
}
.his_label .label{
    width: 100%;
    clear: both;
    height: 20px;
    display: block;
    margin: 15px auto;
    font-size: 13px;
}
.his_result .el-textarea {
    position: relative;
    clear: both;

    display: block;
    width: 100% !important;
    vertical-align: bottom;
    font-size: var(--el-font-size-base);
    padding-right: 1%;
    max-height: 200px;
}
.his_result .el-col{
    border: #cdcdcd solid 1px;
    border-radius: 5px;
    height: 200px;
    overflow: scroll;
    padding: 1px 5px;
}

.el-textarea {
    position: relative;
    display: inline-block;
    width: 86% !important;
    vertical-align: bottom;
    font-size: var(--el-font-size-base);
    padding-right: 1%;
    max-height: 200px;
}
.el-button{
    width: 20%;
    max-width: 100px;
}
.el-button>span {
    display: inline-flex;
    align-items: center;
    font-size: 10px;
}

#content .info-box{
    width: 100%;
    display: inline-flex;
    margin: 10px 0;
    font-size: 12px;
    border-bottom: #ececec solid 1px;
}

.el-row {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    margin-right: 20px;
}
.factor-box{
    width: 50%;
    text-align: center;
    /*display: inline-block;*/
    margin: 10px auto;
    font-size: 12px;
    padding-top: 20px;
}
.factor-box .el-row p{
    text-align:center; 
    width: 100%; 
    font-size: 16px;
}
.file-btn{
    height: 30px;
    width: 180px;
    font-size: 12px;
    line-height: 30px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    border: 1px solid #666666;
    margin-left: 20px;
}
.clear{
    clear: both;
}
.factor-items{
    display: inline-block !important;
    margin: 0 !important;
    /*margin: 10px 5px;*/
    padding: 10px 5px;
    width:50%;
    float: left;
}
.factor-items .el-input{
    width: 20%;
    display: inline-flex;
    margin-right: 1%;
}
.factor-items .el-button{
    width: 15%;
}
.el-textarea__inner{
    max-height: 220px;
}
</style>